main {
    width: 100%;
}

main .section-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px 0px;
    row-gap: 50px;
}

main .section-4 .section-4-1,
main .section-4 .section-4-2 {
    width: 60%;
    display: flex;
    justify-content: center;
}

main .section-4 .section-4-1 .section-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}

main .section-4 .section-4-1 .section-main h1 {
    font-size: 40px;
    line-height: 42px;
    color: #1d1b67;
    text-align: center;
}

main .section-4 .section-4-1 .section-main p {
    color: #425466;
    line-height: 24px;
    text-align: center;
}

main .section-4 .section-4-2 .section-main div.pricing-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-header .pricing-checkbox {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-header .pricing-checkbox span {
    font-size: 14px;
    color: #425466;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-header .pricing-checkbox>div {
    width: 45px;
    background: #1d1b67;
    height: 24px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-header .pricing-checkbox>div div {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: #fff;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    border-radius: 50%;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-header .pricing-checkbox>div.anually div {
    left: unset;
    right: 3px;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div {
    display: none;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div {
    display: flex;
    column-gap: 17px;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card {
    padding: 25px;
    border-radius: 8px;
    width: 360px;
    position: relative;
    border: 1px solid #b5b6b87d;
    /* box-shadow: 0px 1px 10px #bfa9a9; */
    height: -moz-fit-content;
    height: 40vh;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-header {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-header .card-price {
    font-size: 26px;
    color: #336230;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-header .card-price span {
    font-size: 14px;
    color: darkgray;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-header p {
    color: #425466;
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-body {
    padding-top: 25px;
    border-top: 1px solid lightgray;
    margin-top: 15px;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-body ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-body ul li {
    display: flex;
    justify-content: flex-start;
}
main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-body ul li img{
    margin-right: 20px;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-body ul li img {
    width: 20px;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-body ul li::before {
    color: #336230;
    font-weight: bold;
    width: 20px;
    margin-left: -18px;
    font-size: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card .card-footer a {
    background: #336230;
    color: #f0eeeb;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    width: 100%;
    text-align: center;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card:nth-of-type(2) .card-footer button {
    background: #1d1b67;
    color: #fff;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card:nth-of-type(2) .card-footer button:hover {
    background: transparent;
    color: #b5b6b8;
}

main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div.active {
    display: block;
}
.card-footer a:hover{
    background-color: #04283a !important;
}



@media only screen and (max-width: 1200px) {
    main .section-4 {
        padding: 65px 0px 35px 0px;
    }

    main .section-4 .section-4-1,
    main .section-4 .section-4-2 {
        width: 90%;
    }

    main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div {
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 20px;
    }

    main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card {
        width: calc(50% - 10px);
        height: unset;
    }

    main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card:last-child {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    main .section-4 .section-4-2 .section-main div.pricing-body .pricing-body-plans>div>div .card {
        width: 100%;
        height: fit-content;
    }
}

.auto-height{
    height: auto;
}
.height-vh{
    height: 70vh;
}
.heading-h1{
    font-size: 50px;
    font-weight: bold;
}