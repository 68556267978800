.swiper {
  width: 100%;
  height: 100vh;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet {
  display: none !important;
}
.swiper-button-prev {
  display: none !important;
}
.swiper-button-next {
  display: none !important;
}


.swiper-container {
  width: 100%;
  height: 100%;
}

.mySwiper {
  position: relative;
  overflow: hidden;
}

.swiper-slide {
  position: relative;
  height: 100vh;
  display: flex;
}

.swiper-slide h1 {
  margin: 0;
  padding: 20px;
  color: #fff;
}

.gray-background {
  position: "absolute";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s;
}


.swiper-text{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.swiper-one {
  position: relative;
}

.box {
  width: 250px;
  height: 250px;
  background-color: #04283a;
  border-radius: 50%;
}

.box.left {
  animation: moveBoxLeft 2s infinite alternate;
  margin-left: -10%;
}

.box.right {
  animation: moveBoxRight 2s infinite alternate;
  margin-right: -10%;
}

@media (max-width: 768px) {
  .box {
    width: 100px;
    height: 100px;
  }
  .box.right {
    margin-right: 0;
  }
  .box .left {
    margin-left: 0;
  }
}


@keyframes moveBox {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.swiper-one {
  position: relative;
}

/* swiper.css */

@keyframes moveBoxLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveBoxRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
